.ant-checkbox-input {
  border-radius: 1px !important;
}

.ant-checkbox-disabled{
  color: white !important;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
  animation-name: none;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
}

