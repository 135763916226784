.ant-tour-title{
  font-size: 20px !important;
  font-weight: bolder !important;
  text-align: center !important;
  margin-top: 20px !important;
}

.ant-tour{
  background-color: var(--background-base) !important;
}

.ant-tour-inner{
  background-color: var(--background-base) !important;
}

.ant-tour-footer{
  padding: 26px 26px 26px 26px  !important;
}

.ant-tour-indicator{
  width: 8px !important;
  height: 8px !important;
}

.ant-tour-arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--background-base) !important; /* Change color as needed */
  position: absolute;
  transform: translateX(-50%);
}

