//.ant-card-bordered {
//  border: none !important;
//}

.ant-card-body {
  padding: 0 !important;
}

.ant-card {
  background-color: var(--background-base) !important;
}

.ant-card-head {
  font-weight: bold !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-bottom: none !important;
  min-height: 60px !important;
}

.ant-card-head-title {
  font-size: 1.1em !important;
}

