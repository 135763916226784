.ant-table-cell {
  color: #F9FAFA;
}

.ant-table-cell::before {
  content: "" !important;
}

.ant-table-row {
  background-color: var(--background-base);
}

.ant-table-expanded-row {
  background-color: var(--background-base);
}

.ant-table-cell{
  background-color: var(--background-base) !important;
}

.ant-table-cell:hover {
  background-color: var(--background-base) !important;
}

.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: var(--background-base) !important;
}

.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td{
  background: var(--background-base) !important;
}

.ant-table-footer{
  background-color: var(--background-base) !important;
}

.ant-table-wrapper .ant-table{
  background: var(--background-base) !important;
}
