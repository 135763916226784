.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--primary-color-5) !important;
}

.ant-steps-item-active{
  .ant-steps-icon{
    color: var(--primary-color) !important;
  }
  .ant-steps-item-title{
    color: var(--primary-color) !important;
  }
}

.ant-steps-item-icon{
  background-color: @p-white !important;
}

.ant-steps-item-container:hover{
  color: var(--primary-color) !important;
}

.ant-steps-item-finish{
  .ant-steps-item-icon{
    background-color: var(--primary-color-5) !important;
  }

  .ant-steps-item-title{
    color: var(--primary-color) !important;
  }
}
