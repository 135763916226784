:root {
  --primary-color: #6318FF;
  --primary-color-2: #8246FF;
  --primary-color-3: #A274FF;
  --primary-color-4: #C1A2FF;
  --primary-color-5: #E0D1FF;
  --primary-color-6: #F7F3FF;
  --border-radius-base: 2px;
  --background-base: #141517;
}
/* Components */
.ant-tabs-tab {
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0 !important;
  border: none !important;
  margin-right: 5px !important;
}
.ant-tabs-nav {
  margin: 0 !important;
}
.ant-tabs-tab-active {
  border: none !important;
  background: var(--background-base) !important;
}
.ant-tabs-nav::before {
  display: none !important;
}
.ant-tabs-tabpane {
  background-color: var(--background-base) !important;
  border-radius: var(--border-radius-base) !important;
}
/* Base appearance of the tab navigation */
.custom-card-tabs .ant-tabs-nav {
  background-color: var(--background-base);
  /* Background matching the card color */
  border-radius: 4px 4px 0 0;
}
/* Appearance of each individual tab */
.custom-card-tabs .ant-tabs-tab {
  font-weight: bold !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-bottom: none !important;
  min-height: 60px !important;
  margin: 0 12px;
  border-radius: 4px 4px 0 0;
  /* Rounded top corners */
  font-size: 18px;
  background-color: var(--background-base) !important;
  transition: all 0.3s ease;
  /* Ensuring a smooth transition for effects */
}
.custom-card-tabs .ant-tabs-tab:last-child {
  border-right: none;
}
/* Appearance and effect on the active tab */
.custom-card-tabs .ant-tabs-tab-active {
  background-color: #141517;
  /* Matching with card background color */
  color: #ffffff;
  /* Pure white color for active tab text */
  border: 1px solid transparent;
  /* Remove border from active tab */
  box-shadow: 0 4px 12px rgba(20, 21, 23, 0.5);
  /* Shadow effect on the active tab */
}
/* Border for the content area */
.custom-card-tabs .ant-tabs-content {
  border-top: 1px solid #303030;
}
.ant-tabs-nav {
  border-radius: 0 !important;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #303030 !important;
}
.ant-collapse {
  background-color: transparent !important;
}
.ant-tour-title {
  font-size: 20px !important;
  font-weight: bolder !important;
  text-align: center !important;
  margin-top: 20px !important;
}
.ant-tour {
  background-color: var(--background-base) !important;
}
.ant-tour-inner {
  background-color: var(--background-base) !important;
}
.ant-tour-footer {
  padding: 26px 26px 26px 26px  !important;
}
.ant-tour-indicator {
  width: 8px !important;
  height: 8px !important;
}
.ant-tour-arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--background-base) !important;
  /* Change color as needed */
  position: absolute;
  transform: translateX(-50%);
}
.ant-list-empty-text {
  padding: 0 !important;
}
.ant-table-cell {
  color: #F9FAFA;
}
.ant-table-cell::before {
  content: "" !important;
}
.ant-table-row {
  background-color: var(--background-base);
}
.ant-table-expanded-row {
  background-color: var(--background-base);
}
.ant-table-cell {
  background-color: var(--background-base) !important;
}
.ant-table-cell:hover {
  background-color: var(--background-base) !important;
}
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: var(--background-base) !important;
}
.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: var(--background-base) !important;
}
.ant-table-footer {
  background-color: var(--background-base) !important;
}
.ant-table-wrapper .ant-table {
  background: var(--background-base) !important;
}
.ant-tag {
  border-radius: var(--border-radius-base);
}
.ant-tooltip-inner {
  font-size: 14px !important;
  border-radius: 6px;
  background-color: black !important;
  font-weight: 400;
  padding: 8px;
}
/* CSS for the custom arrow */
.custom-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent black transparent !important;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(21, 5, 54, 0.3);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-card-body {
  padding: 0 !important;
}
.ant-card {
  background-color: var(--background-base) !important;
}
.ant-card-head {
  font-weight: bold !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border-bottom: none !important;
  min-height: 60px !important;
}
.ant-card-head-title {
  font-size: 1.1em !important;
}
.ant-input {
  border-radius: var(--border-radius-base) !important;
  background-color: var(--background-base) !important;
}
.ant-input:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}
.ant-input-affix-wrapper {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
  border-radius: var(--border-radius-base);
  height: 50px;
  font-size: 16px;
}
textarea {
  resize: none;
}
.ant-form-item-children-icon {
  font-size: 20px !important;
  margin-right: 5px;
  background-color: var(--background-base) !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--primary-color-5) !important;
}
.ant-steps-item-active .ant-steps-icon {
  color: var(--primary-color) !important;
}
.ant-steps-item-active .ant-steps-item-title {
  color: var(--primary-color) !important;
}
.ant-steps-item-icon {
  background-color: #F3F3F5 !important;
}
.ant-steps-item-container:hover {
  color: var(--primary-color) !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--primary-color-5) !important;
}
.ant-steps-item-finish .ant-steps-item-title {
  color: var(--primary-color) !important;
}
.ant-checkbox-input {
  border-radius: 1px !important;
}
.ant-checkbox-disabled {
  color: white !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white !important;
  animation-name: none;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary-color);
  border-color: var(--primary-color) !important;
}
:root {
  --focus-border: var(--primary-color) !important;
  --separator-border: #30302D !important;
  --sash-size: 8px;
  --sash-hover-size: 4px;
}
.ant-modal-header {
  font-weight: bold !important;
  padding-right: 15px !important;
  border-bottom: none !important;
  background-color: var(--background-base) !important;
}
.ant-modal-title {
  font-size: 1.2em !important;
}
.ant-modal-body {
  background-color: var(--background-base) !important;
}
.ant-modal-content {
  border-radius: var(--border-radius-base);
  background-color: var(--background-base) !important;
}
.ant-modal-footer {
  border-top: none !important;
  background-color: var(--background-base) !important;
}
/* Layout */
.aside {
  font-family: Avenir, "Red Hat Display", -apple-system, BlinkMacSystemFont, serif;
  padding: 36px;
  background-color: #DEDBE5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.aside .title {
  font-size: 20px;
  line-height: 25px;
  text-align: left;
  font-weight: 400;
  color: #44375E;
}
.aside .return {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  line-height: 19px;
  color: #736986 !important;
}
.aside .return a {
  color: #736986 !important;
}
body {
  margin: 0;
  font-family: 'Red Hat Display', 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-base) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.logo {
  text-align: left;
}
.logo img {
  vertical-align: middle;
  border-style: none;
  max-height: 34px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  align-items: center;
  display: flex;
  background: black;
  padding: 15px 5px;
  margin: 10px;
}
.font-w-400 {
  font-weight: 400;
}
.font-w-500 {
  font-weight: 500;
}
.font-w-600 {
  font-weight: 600;
}
.font-w-700 {
  font-weight: 700;
}
.font-s-12 {
  font-size: 12px;
}
.font-s-16 {
  font-size: 16px;
}
.font-s-18 {
  font-size: 18px;
}
.font-s-24 {
  font-size: 24px;
}
.text-primary {
  color: var(--primary-color) !important;
}
.text-dark-black {
  color: #150536 !important;
}
.text-grey {
  color: #A29BAE !important;
}
.text-dark-grey {
  color: #736986 !important;
}
.text-danger {
  color: #FF5050 !important;
}
.text-white {
  color: white !important;
}
.vertical-middle {
  vertical-align: middle !important;
}
.text-hover-danger:hover {
  color: #FF5050 !important;
}
.text-hover-primary:hover {
  color: var(--primary-color) !important;
}
.border-hover-danger:hover {
  border-color: #FF5050 !important;
}
.bg-primary {
  background-color: var(--primary-color) !important;
}
.bg-primary-2 {
  background-color: var(--primary-color-2) !important;
}
.bg-primary-3 {
  background-color: var(--primary-color-3) !important;
}
.bg-primary-4 {
  background-color: var(--primary-color-4) !important;
}
.bg-primary-5 {
  background-color: var(--primary-color-5) !important;
}
.bg-primary-6 {
  background-color: var(--primary-color-6) !important;
}
.bg-white {
  background-color: #F3F3F5;
}
.border-none {
  border: none !important;
}
.border-radius-right-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.border-radius-left-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.border-right-none {
  border-right: none !important;
}
.border-primary {
  border-color: var(--primary-color) !important;
}
.default-border-radius {
  border-radius: var(--border-radius-base) !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-12 {
  margin-top: 12px !important;
}
.mt-24 {
  margin-top: 24px !important;
}
.mt-36 {
  margin-top: 36px !important;
}
.mt-48 {
  margin-top: 48px !important;
}
.mb-12 {
  margin-bottom: 12px !important;
}
.mb-24 {
  margin-bottom: 24px !important;
}
.mb-36 {
  margin-bottom: 36px !important;
}
.mb-48 {
  margin-bottom: 48px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-12 {
  margin-left: 12px !important;
}
.ml-24 {
  margin-left: 24px !important;
}
.mr-16 {
  margin-right: 16px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mr-12 {
  margin-right: 12px !important;
}
.mr-24 {
  margin-right: 24px !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mt-auto {
  margin-top: auto;
}
.ml-16 {
  margin-left: 16px !important;
}
.m-10 {
  margin: 10px;
}
.m-20 {
  margin: 20px;
}
.ml-auto {
  margin-left: auto !important;
}
.mb-auto {
  margin-bottom: auto;
}
.p-10 {
  padding: 10px !important;
}
.p-12 {
  padding: 12px !important;
}
.px-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.pt-6 {
  padding-top: 6px;
}
.pl-2 {
  padding-left: 2px;
}
.pt-12 {
  padding-top: 12px;
}
.pt-20 {
  padding-top: 20px !important;
}
.pb-12 {
  padding-bottom: 12px;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.gly-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}
.center-of-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
.overflow-auto {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-none {
  display: none !important;
}
.flex-auto {
  flex: 1 1 auto;
}
.d-inline-flex {
  display: inline-flex !important;
}
.flex-col {
  flex-direction: column;
}
.flex {
  display: flex !important;
}
.flex-shrink {
  flex-shrink: 1 !important;
}
.v-align-middle {
  vertical-align: middle !important;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.max-h-full {
  max-height: 100%;
}
.w-50 {
  width: 50%;
}
.rounded {
  border-radius: var(--border-radius-base) !important;
}
.ant-list-item-meta-title {
  margin-bottom: 0 !important;
}
.cm-gutters {
  background-color: #141517 !important;
}
.cm-gutter .cm-foldGutter {
  background-color: #141517 !important;
}
.cm-gutterElement {
  background-color: #141517 !important;
}
.wasm-loader-background {
  background-color: #000000;
  opacity: 1;
  background-image: radial-gradient(#434343 0.55px, #000000 0.55px);
  background-size: 11px 11px;
}
.spot-background {
  background-color: var(--background-base);
  opacity: 1;
  background-image: radial-gradient(#434343 0.55px, var(--background-base) 0.55px);
  background-size: 11px 11px;
}
.scroll {
  max-height: 74vh !important;
  overflow-y: auto;
}
.monaco-editor .bracket-highlighting-0 {
  color: white !important;
}
.ant-btn-default {
  background-color: transparent !important;
  box-shadow: none;
}
.ant-black-button {
  background-color: black !important;
}
.ant-select-selector {
  background-color: transparent !important;
}
.ant-radio-wrapper .ant-radio-inner::after {
  background-color: var(--background-base) !important;
}
.ant-radio-button-wrapper {
  background-color: var(--background-base) !important;
}
.ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse-item {
  background-color: var(--background-base) !important;
}
.ant-collapse {
  border: none !important;
}
.error-row .ant-collapse-header {
  color: #dc4446 !important;
}
.error-row .ant-table-cell {
  color: #dc4446 !important;
}
.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #dc4446 !important;
}
.text-center {
  text-align: center !important;
}
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
