.ant-modal-header{
  font-weight: bold !important;
  padding-right: 15px !important;
  border-bottom: none !important;
  background-color: var(--background-base) !important;
}

.ant-modal-title{
  font-size: 1.2em !important;
}

.ant-modal-body{
  background-color: var(--background-base) !important;
}

.ant-modal-content{
  border-radius: var(--border-radius-base);
  background-color: var(--background-base) !important;
}

.ant-modal-footer{
  border-top: none !important;
  background-color: var(--background-base) !important;
}
