.ant-tooltip-inner {
  font-size: 14px !important;
  border-radius: 6px;
  background-color: black !important;
  font-weight: 400;
  padding: 8px;
}

/* CSS for the custom arrow */
.custom-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent black transparent !important;
}
