.ant-input {
  border-radius: var(--border-radius-base) !important;
  background-color: var(--background-base) !important;
}

.ant-input:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}

.ant-input-affix-wrapper {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
  border-radius: var(--border-radius-base);
  height: 50px;
  font-size: 16px;
}

textarea {
  resize: none;
}
