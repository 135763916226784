:root {
  // color
  --primary-color: #6318FF;
  --primary-color-2: #8246FF;
  --primary-color-3: #A274FF;
  --primary-color-4: #C1A2FF;
  --primary-color-5: #E0D1FF;
  --primary-color-6: #F7F3FF;

  // border
  --border-radius-base: 2px;

  // background
  --background-base: #141517;
}

@p-secondary: #85A392;
@p-secondary-2: #9DB5A8;
@p-secondary-3: #B6C8BE;
@p-secondary-4: #CEDAD3;
@p-secondary-5: #E7EDE9;
@p-secondary-6: #F9FAFA;

@p-dark-black: #150536;
@p-black: #44375E;
@p-dark-grey: #736986;
@p-grey: #A29BAE;

@p-dark-white: #DEDBE5;
@p-white: #F3F3F5;
