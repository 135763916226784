.aside {
    font-family: Avenir, "Red Hat Display", -apple-system, BlinkMacSystemFont, serif;
    padding: 36px;
    background-color: @p-dark-white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.aside .title {
    font-size: 20px;
    line-height: 25px;
    text-align: left;
    font-weight: 400;
    color: @p-black;
}

.aside .return {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 19px;
    color: @p-dark-grey !important;
}

.aside .return a {
    color: @p-dark-grey !important;
}
